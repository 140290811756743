import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

html {
  main{
    opacity: 0;
    transition: 250ms opacity ease;
  }
}

html.wf-active,
html.wf-inactive {
  main{
    opacity: 1;
  }
}

body {
  font-family: "Wasa-Var", -apple-system, system-ui, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-variation-settings: "wght" 600;

  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.35px;

  text-rendering: optimizeLegibility;

  color: #000;
  margin: 0;

  @media (max-width: 900px) {
    font-size: 15px;
    line-height: 18px;
  }

  @media (max-width: 500px) {
    font-size: 13px;
    line-height: 16px;
  }
}

input {
  border-radius: 0;
}

h1,h2,h3,h4,h5{
  font-family: "Wasa-Var", -apple-system, system-ui, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

  font-weight: normal;

  margin: 0;
}


/* Title 1 */
h1{
  font-size: 70px;
  line-height: 70px;
  letter-spacing: 0.3px;

  font-weight: normal;

  @media (max-width: 1000px) {
    font-size: 50px;
    line-height: 36px;
  }

  @media (max-width: 500px) {
    font-size: 30px;
  }
}

/* Title 2 */
h2 {
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 0.2px;
}

/* Heading 1 */
h3 {
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 0.2px;
}

/* Heading 2 */
h4 {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

/* Heading 3 */
h5 {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.3px;
}


/*--------------------------------------------------------------
  ## Media
  --------------------------------------------------------------*/

video,
img {
  width: 100%;
  margin: 0;

  display: block;
}

/*--------------------------------------------------------------
  ## Buttons
  --------------------------------------------------------------*/

  button{
    background-color: transparent;

    border: 0;
    margin: 0;
    padding: 0;

    cursor:pointer;

    &:focus,
    &:hover{
      outline: none;
    }
  }

/*--------------------------------------------------------------
  ## Links
  --------------------------------------------------------------*/
a {
  color: inherit;
  text-decoration: none;

  position: relative;
}

a:visited {
  color: inherit;
}

a:hover,
a:focus,
a:active {
  color: inherit;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

/*--------------------------------------------------------------
  ## Text
  --------------------------------------------------------------*/

p {
  margin: 1em 0;
}

ul, li{
  list-style: none;
  padding: 0;
  margin: 0;
}

/*--------------------------------------------------------------
  ## Box Sizing / Layout
  --------------------------------------------------------------*/

div {
  box-sizing: border-box;
}

`;

export default GlobalStyle;
