import * as React from "react";
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews";

// Prismic
import { linkResolver } from "./src/components/link-resolver/linkResolver";

// Config
const repositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
    componentResolver: {
      about: React.lazy(() => import("./src/pages/about")),
      homepage: React.lazy(() => import("./src/pages/index")),
      project: React.lazy(() => import("./src/templates/project")),
    },
  },
];

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
    {element}
  </PrismicPreviewProvider>
);
