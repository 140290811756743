import React from "react";
import styled from "styled-components";

// Styles
import GlobalStyles from "../styles/globalStyles";
import "../styles/normalize.css";

// Components
import { DefaultSEO } from "./default-seo";

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const Layout = ({ children }) => (
  <Container>
    <GlobalStyles />
    <DefaultSEO />
    <main>{children}</main>
  </Container>
);

export default Layout;
